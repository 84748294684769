<template>
  <div>
    <div v-if="!isMobile">
      <div class="boxtoimg">
        <div class="box1200auto min-vh100 border-box pt200 color-white">
          <div class="fs30 fw600">登记信息</div>
          <div class="fs16 mt20">
            登记您的信息，我们将为您提供卓阳最新的产品信息及优质的产品服务。
          </div>
          <!-- 下面的表单 -->
          <el-form :model="formdata" ref="ruleForm" :rules="rules">
            <el-row :gutter="20" class="pt30">
              <el-col :span="6" class="">
                <el-form-item label="" prop="name">
                  <div class="fs20 pb10">姓名</div>
                  <el-input
                    v-model="formdata.name"
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="" prop="tel">
                  <div class="fs20 pb10">电话</div>
                  <el-input
                    v-model="formdata.tel"
                    maxlength="11"
                    placeholder="请输入电话"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item prop="company">
                  <div class="fs20 pb10">公司</div>
                  <el-input
                    v-model="formdata.company"
                    placeholder="请输入公司名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="cangery">
                  <div class="fs20 pb10">留言</div>
                  <el-input
                    v-model="formdata.cangery"
                    placeholder="请输入留言"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="15" class="pt50">
              <el-col :span="4">&nbsp;</el-col>

              <el-col
                :span="4"
                class="b-a-1 pt5 pb5 br5 pl10 pr10 text-center changheand"
              >
                <div @click="submitForm('ruleForm')">确认</div>
              </el-col>
              <el-col :span="5">&nbsp;</el-col>
            </el-row>
          </el-form>
          <!-- // 联系我们 -->
          <div>
            <div class="fs30 fw600 pt50">联系我们</div>
            <div>
              <el-row :gutter="20" class="pt30">
                <el-col :span="6" class="">
                  <div class="fs18 pb10">客服：021-3750 9071</div>
                  <div class="fs18 pb10">人事：021-5296 5907</div>
                  <div class="fs18 pb10">地址：1777号e通世界华新园2号楼</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile">
      <div class="boxtoimg1 pl15 pr15 pt15">
        <div class="min-vh100 border-box pt200 color-white">
          <div class="fs30 fw600">登记信息</div>
          <div class="fs16 mt20">
            登记您的信息，我们将为您提供卓阳最新的产品信息及优质的产品服务。
          </div>
          <!-- 下面的表单 -->
          <el-form :model="formdata" ref="ruleForm" :rules="rules">
            <div class="pt30 pl30 pr30">
              <div class="flex align-items-center flex-center">
                <div class="fs20 pr15" style="margin-bottom: 22px">姓名</div>
                <el-form-item
                  label=""
                  label-width="auto"
                  class="flex-1"
                  prop="name"
                >
                  <el-input
                    v-model="formdata.name"
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex align-items-center flex-center">
                <div class="fs20 pr15" style="margin-bottom: 22px">电话</div>
                <el-form-item
                  label-width="auto"
                  class="flex-1"
                  label=""
                  prop="tel"
                >
                  <el-input
                    v-model="formdata.tel"
                    maxlength="11"
                    placeholder="请输入电话"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex align-items-center flex-center">
                <div class="fs20 pr15" style="margin-bottom: 22px">公司</div>
                <el-form-item prop="company" class="flex-1" label-width="auto">
                  <el-input
                    v-model="formdata.company"
                    placeholder="请输入公司名称"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex align-items-center flex-center">
                <div class="fs20 pr15" style="margin-bottom: 22px">留言</div>
                <!-- <el-form-item prop="cangery"> -->
                <el-input
                  type="textarea"
                  class="flex-1"
                  :rows="2"
                  v-model="formdata.cangery"
                  placeholder="请输入留言"
                ></el-input>
                <!-- </el-form-item> -->
              </div>
            </div>
          </el-form>
          <div class="pt20 flex flex-center width-full">
            <div class="b-a-1 pt5 pb5 br5 pl50 pr50 text-center changheand">
              <div @click="submitForm('ruleForm')">确认</div>
            </div>
          </div>
          <!-- // 联系我们 -->
          <div>
            <div class="fs30 fw600 pt50">联系我们</div>
            <div>
              <div class="pt30 text-center">
                <div class="">
                  <div class="fs18 pb10">客服：021-3750 9071</div>
                  <div class="fs18 pb10">人事：021-5296 5907</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { lookList } from "@/requesturl/user.js";
import p1 from "@/assets/images/addpreore/p1.jpg";

import { mapGetters } from "vuex";
import { look_mixin } from "@/utils/looktime.js";
export default {
  computed: {
    ...mapGetters(["isMobile"]),
  },
  data() {
    return {
      imgs: p1,
      formdata: {
        name: "",
        tel: "",
        company: "", // 公司
        cangery: "", // 留言
      },

      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: this.isMobileNumber,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
      },
    };
  },
  mixins: [look_mixin],
  mounted() {},
  methods: {
    isMobileNumber(rule, value, callback) {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {
          //判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) {
            //判断是否为11位手机号
            callback(new Error("手机号码格式如:138xxxx7654"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }
    },
    submitForm(formName) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.user_btn_click("登记信息", "/addpreore", "确认");
          lookList({
            name: this.formdata.name,
            frim: this.formdata.company,
            phone: this.formdata.tel,
            message: this.formdata.cangery,
          }).then((res) => {
            // console.log("请求成功了", res);
            loading.close();
            if (res.data) {
              alert(
                "感谢您的反馈与关注，我们已经搜集到您的需求，将会在近期与您取得联系！"
              );
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footlogo {
  width: 141px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
  }
}
.erwmgzh {
  width: 110px;
  height: 110px;
  img {
    width: 100%;
    height: 100%;
  }
}
.botombox {
  width: 177px;
}
.width30px {
  width: 150px;
}
.boxtoimg {
  width: 100%;
  background: url("../../assets/images/addpreore/p1.jpg") no-repeat center
    center;
  background-size: cover;
}
.boxtoimg1 {
  width: 100%;
  background: url("../../assets/images/addpreore/mp1.jpg") no-repeat center
    center;
  background-size: cover;
}
</style>